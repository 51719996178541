import { render, staticRenderFns } from "./OrderTotalSummary.vue?vue&type=template&id=0a4f4296&scoped=true"
import script from "./OrderTotalSummary.vue?vue&type=script&lang=js"
export * from "./OrderTotalSummary.vue?vue&type=script&lang=js"
import style0 from "./OrderTotalSummary.vue?vue&type=style&index=0&id=0a4f4296&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a4f4296",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/amandapascon/Desktop/Projetos/Backlgrs/Suzano/backlgrs.sfcc.suzano/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0a4f4296')) {
      api.createRecord('0a4f4296', component.options)
    } else {
      api.reload('0a4f4296', component.options)
    }
    module.hot.accept("./OrderTotalSummary.vue?vue&type=template&id=0a4f4296&scoped=true", function () {
      api.rerender('0a4f4296', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "cartridges/app_suzano_custom/cartridge/client/default/js/vueComponents/components/checkout/OrderTotalSummary.vue"
export default component.exports