import { render, staticRenderFns } from "./Breadcrumb.vue?vue&type=template&id=b284515e"
import script from "./Breadcrumb.vue?vue&type=script&lang=js"
export * from "./Breadcrumb.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/amandapascon/Desktop/Projetos/Backlgrs/Suzano/backlgrs.sfcc.suzano/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b284515e')) {
      api.createRecord('b284515e', component.options)
    } else {
      api.reload('b284515e', component.options)
    }
    module.hot.accept("./Breadcrumb.vue?vue&type=template&id=b284515e", function () {
      api.rerender('b284515e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "cartridges/app_suzano_custom/cartridge/client/default/js/vueComponents/components/Breadcrumb.vue"
export default component.exports